<template>
  <section class="slothmove ">
    <h1 class="text-white">SlothMove</h1>
    <p class="description">
      Update your address across hundreds of providers with this award-winning change of address
      service. You can create a new move at any time by following the portal below 🦥
    </p>

    <div class="miniform mt-3">
      <div class="text-center mb-2">
        <img
          :src="`${urlBucket}/dashboard/sloth-move-header-minifrom.png`"
          class="img-fluid"
          width="335px"
          alt=""
        />
      </div>

      <p style="font-size: 15px" class="text-left font-weight-bolder">
        Who needs to use SlothMove?
      </p>

      <div class="selection row mb-2">
        <div class="col ml-1 mr-1" style="padding: 4px 2px">
          <div
            class="d-flex align-items-center justify-content-center p-1 squares hvr hvr-radial-out"
            :class="{ selected: me }"
            @click="
              () => {
                me = !me;
                family = false;
              }
            "
          >
            <div>
              <img :src="`${urlBucket}/dashboard/me.png`" class="img-fluid" />
              <p class="font-weight-bolder mb-0 fs-sm text-black">Me</p>
            </div>
          </div>
        </div>
        <div class="col" style="padding: 4px 2px">
          <div
            class="d-flex align-items-center justify-content-center p-1 squares hvr hvr-radial-out"
            :class="{ selected: family }"
            @click="
              () => {
                family = !family;
                me = false;
              }
            "
          >
            <div>
              <img :src="`${urlBucket}/dashboard/family.png`" class="img-fluid" />

              <p class="font-weight-bolder mb-0 fs-sm text-black">Family</p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="me || family"
        class="label d-flex align-items-center justify-content-center mt-1 mb-2 py-1 animate__animated animate__pulse"
      >
        <img :src="`${urlBucket}/dashboard/check-y.png`" width="30px" alt="" class="mr-1" />
        <p class="ms-1 mb-0">Excellent, please click below to get started</p>
      </div>

      <button class="button d-flex align-items-center justify-content-center" @click="submit()">
        <p class="mb-0 font-weight-bolder mr-1" style="font-size: 18px">Get Started</p>
        <div class="spinner-border text-light" role="status" v-if="loading"></div>
      </button>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axiosCustom from "@axios";
import Vue from "vue";

export default {
  name: "SlothMove",
  components: {},
  data() {
    return {
      loading: false,
      me: null,
      family: null,
    };
  },
  mounted() {},
  methods: {
    submit() {
      if (!this.me && !this.family) return;

      this.loading = true;

      let url = "";
      let move = this.lastMove.moveDetails;
      let redirect = null; //function

      if (this.me) {
        url = `/encrypted/createAddressUpdaterEncode?s=${this.user.email}`;
        redirect = (key) => window.open(`https://form.slothmove.com?addressUpdater=${key}`);
      }

      if (this.family) {
        url = `/encrypted/createAddressUpdaterEncode?s=${this.user.email}*${move.moveFromAddress}*${move.moveFromPostCode}*${move.moveToAddress}*${move.moveToPostCode}`;
        redirect = (key) => window.open(`https://form.slothmove.com?familyUpdater=${key}`);
      }

      console.log(url);

      axiosCustom
        .get(url)
        .then((result) => {
          console.log(result.data);
          redirect(result.data.key)
        })
        .catch((err) => {})
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapState("app", ["user", "urlBucket", "currentMove", "logoPartner", "lastMove"]),
    ...mapGetters("app", ["isAPartner", "colorPartner"]),
  },
  watch: {},
};
</script>

<style lang="scss">
.slothmove {
  margin-left: 12em;

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }

  h1 {
    font-weight: bold;
  }



  p {
    color: white;
    font-size: 16px;
  }

  .miniform,
  .description {
    max-width: 550px;
    text-align: justify;
  }

  .description {
    line-height: 1.7;
  }

  .miniform {
    border: 5px solid #18659f;
    border-radius: 30px;
    background-color: #212534;
    padding: 27px 40px;
    padding-top: 15px;

    .selection {
      .col {
        max-width: 125px;
      }
      .squares {
        text-align: center;
        background: #fff;
        border-radius: 5px;
        cursor: pointer;
        color: #000;
        box-shadow: 0 1px 7px rgba(11, 45, 150, 0.2);
        transition: all 0.1s ease-in-out;

        img {
          width: 70px;
          height: 67px;
        }

        p {
          color: #000;
        }
      }

      .selected {
        background-color: #00b9ee;
        p {
          color: white;
        }
      }
    }

    p {
      color: white;
    }

    .label {
      background-color: rgba(57, 61, 74, 0.62);
      border-radius: 10px;
      padding: 6px;
    }

    button {
      all: unset;
      box-sizing: border-box;
      width: 100%;
      display: block;
      padding: 15px;
      color: white;
      text-align: center;
      letter-spacing: 1.5px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 13px;
      font-size: 16px;
    }

    .button {
      background-color: #ec0677;
      box-shadow: 0 5px 0 #7d0741;
    }

    .button:active {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
}
</style>
